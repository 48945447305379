<template>
  <div class="container">
    <div class="row">
      <Announcement
        class="col-12 mt-3"
        v-for="paper in papers"
        :key="paper.id"
        :paper="paper" >
      </Announcement>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { LIST_PAPER } from './graph'
import Announcement from './Announcement.vue'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: this.title
    }
  },
  props: {
    profileType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      papers: []
    }
  },
  apollo: {
    papers: {
      query () {
        return LIST_PAPER(this.templateType)
      },
      variables () {
        return {
          profileType: this.profileType
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    Announcement
  }
}
</script>
