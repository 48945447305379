import gql from 'graphql-tag'


export const LIST_PAPER = (templateType) => gql`query LIST_PAPER ($profileType: String!) {
  papers: listProfile${templateType}Paper (profileType: $profileType) {
    id type code name
    contents {
      id order
      title content
      imageContent
      imageContentPreview
      tableContent
      children {
        id order
        title content
        imageContent
        imageContentPreview
        tableContent
        children {
          id order
          title content
          imageContent
          imageContentPreview
          tableContent
        }
      }
    }
  }
}`
