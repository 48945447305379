<template>
  <div>
    <h4 class="text-center">
      {{paperName}}
    </h4>
    <AnnouncementContent
      class="mb-3"
      v-for="content in paper.contents"
      :key="content.id"
      :level="1"
      :paperId="paper.id"
      :content="content">
    </AnnouncementContent>
  </div>
</template>

<script>
import AnnouncementContent from './AnnouncementContent.vue'

export default {
  props: {
    paper: {
      type: Object,
      required: true
    }
  },
  computed: {
    paperName () {
      const arr = this.paper.name.split('_')
      arr.splice(0, 1)
      return arr.join('_')
    }
  },
  components: {
    AnnouncementContent
  }
}
</script>
